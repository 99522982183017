/*=[ FORMS ]================================================================*/

function ContactForm() {
  $('.ajax-form').validate();

  var bar = $('.ajax-form .bar');
  var percent = $('.ajax-form .percent');
  var fileInputMessage = $('.file-upload .filename').html();

  $('.ajax-form').ajaxForm({
    //resetForm: true,
    beforeSubmit: function (formData, jqForm, options) {
      $('#message-sent').hide();
      $('.upload-error').hide();
      $('.progress').show();
      var percentVal = '0%';
      bar.width(percentVal)
      percent.html(percentVal);
      $('.ajax-form').resetForm();
      $('.file-upload .filename').html(fileInputMessage);
      $('.file-upload .filename').removeClass('active');
      $('.ajax-form .submit').addClass('disabled');
      $('.ajax-form .submit').prop('disabled', true);
      return true;
    },
    uploadProgress: function (event, position, total, percentComplete) {
      var percentVal = percentComplete + '%';
      bar.width(percentVal)
      percent.html(percentVal);
    },
    success: function (responseText, statusText, xhr, $form) {
      $('.progress').hide();
      $('.ajax-form .submit').removeClass('disabled');
      $('.ajax-form .submit').prop('disabled', false);
      var response = $.parseJSON(responseText);
      if (response.status == 'OK') {
        if (response.hasOwnProperty('redirect_url')) {
          top.location.replace(response.redirect_url);
        } else {
          $('#message-sent').show();
        }
      } else {
        $('.upload-error').text(response.status);
        $('.upload-error').show();
      }
    }
  });

  $('.ajax-form .file-input').change(function(){
    var files = $(this)[0].files;
    if (files.length > 0) {
      $('.file-upload .filename').html(files[0].name);
      $('.file-upload .filename').addClass('active');
    }
  });
}

/*=[ CLOCK ]================================================================*/

function Clock() {

  var clockShown = false;
  var self = this;

  function formatSrcString(element, prevNum, num, play, update, prevGif) {
    var prevNumString = prevNum > 9 ? prevNum : '0' + prevNum;
    var numString = num > 9 ? num : '0' + num;
    var src = themeLink + '/static/clock/' + prevNumString + '-' + numString + '.gif';

    if (update) {
      var gif = prevGif;
    } else {
      var gif = new SuperGif({ gif: element[0], loop_mode: false, auto_play: false,
        progressbar_height:0 } );
    }
    gif.load_url(src, function(){
      if (play) {
        gif.play();
      }
    });

    return gif;
  }

  function createClock(timeElement, update, gifHours, gifMinutes) {
    var date = new Date();
    var hoursElement = timeElement.find('.hours');
    var minutesElement = timeElement.find('.minutes');

    if (timeElement.data('office') == 'nyc') {
      var newYork = moment.tz(date.getTime(), "America/New_York");
      var hour  = parseInt(newYork.format('H'));
      var minutes = parseInt(newYork.format('m'));
    } else {
      var london = moment.tz(date.getTime(), "Europe/London");
      var hour  = parseInt(london.format('H'));
      var minutes = parseInt(london.format('m'));

    }
    hour += 1;
    var prevHour = hour == 0 ? 23 : hour-1;

    var minutes = date.getMinutes();
    var prevMinutes = minutes == 0 ? 59 : minutes-1;

    var newGifHours = formatSrcString(hoursElement, prevHour, hour,
      false, update, gifHours);
    var newGifMinutes = formatSrcString(minutesElement, prevMinutes, minutes,
      true, update, gifMinutes);

    setTimeout(function(){
      createClock(timeElement, true, newGifHours, newGifMinutes)
    }, 1000*61);
  }

  this.showClock = function() {
    if (!clockShown) {
      $('.time').each(function() {
        var rect = $(this)[0].getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          clockShown = true;
          createClock($(this), false, null, null);
        }
      });
    }
  }

  $(window).on('scroll', function() {
    self.showClock();
  });

}
