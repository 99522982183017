/*=[ HOMEPAGE CAROUSEL ]====================================================*/

function HomeCarousel() {
  var transitionSpeed = 800;

  var currentSlideId = 0;
  var currentSlide, nextSlide;
  var slideIteration = 0;

  var carouselTimer = null, postSlideTimer = null;
  var introVideoPlayed = false;
  var carouselPaused = false;

  var navMargin = 0.35;

  function animateProgressBar(time) {
    var element = $('#progress-bar');

    element.css('animation-name', 'none');

    // restart CSS animation
    void element[0].offsetWidth;

    element.css('animation-name', 'animateProgressBar');
    element.css('animation-duration', time + 'ms');
  }

  function startCarouselTimer(delay) {
    if ($('.section-carousel').length) {
      carouselTimer = new Timer(gotoNextSlide, delay);

      animateProgressBar(delay);
    }
  }

  this.stopCarousel = function() {
    // stop all videos
    if (carouselTimer != null) {
      carouselTimer.pause();
    }
    if (postSlideTimer != null) {
      postSlideTimer.pause();
    }
  }

  function postSlideTransition() {
    currentSlide.removeClass('active');
    nextSlide.addClass('active');

    $('.caption').removeClass('slide-hidden');

    var videoElement = currentSlide.find('video');
    if (videoElement.length) {
      videoElement[0].pause();
    }

    if (!carouselPaused) {
      startCarouselTimer(carouselDelay);
    }
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  function randomizeFirstSlide() {
    //var copyIndex = slideIteration%3;
    var copyIndex = getRandomInt(7);
    var copy = $('.section-carousel .intro-slide-title')
      .data('intro-copy'+copyIndex);
    $('.section-carousel .intro-slide-title').text(copy);
    var subcopy = $('.section-carousel .intro-slide-subtitle')
      .data('intro-subtitle'+copyIndex);
    $('.section-carousel .intro-slide-subtitle').html(subcopy);
  }

  function gotoSlide(slideId) {
    var numSlides = $('.section-carousel .slide').length - 1;
    var forceLeft = false, forceRight = false;
    if (slideId > numSlides) {
      slideId = 0;
      forceLeft = true;
      slideIteration++;
    } else if (slideId < 0) {
      slideId = numSlides;
      forceRight = true;
    }

    if (slideId === 0) {
      randomizeFirstSlide();
    }

    currentSlide = $('.section-carousel .slide.active');
    nextSlide = $('.section-carousel .slide[data-slide="' + slideId + '"]');

    var allBullets =  $('.section-carousel button');
    allBullets.removeClass('active');
    var currentBullet =  $('.section-carousel button[data-slide="' + slideId + '"]');
    currentBullet.addClass('active');

    if ((slideId > currentSlideId && !forceRight)|| forceLeft) {
      currentSlide.css('animation-name', 'carouselCenterToLeft');
      nextSlide.css('animation-name', 'carouselRightToCenter');
    } else if (slideId < currentSlideId || forceRight) {
      currentSlide.css('animation-name', 'carouselCenterToRight');
      nextSlide.css('animation-name', 'carouselLeftToCenter');
    } else {
      console.error('Unexpected path');
    }

    var videoElement = nextSlide.find('video');
    if (videoElement.length) {
      videoElement[0].play();
    }

    $('.caption').addClass('slide-hidden');

    currentSlideId = slideId;

    postSlideTimer = new Timer(postSlideTransition, transitionSpeed+50);
  }

  function gotoPrevSlide() {
    gotoSlide(currentSlideId - 1);
  }

  function gotoNextSlide() {
    gotoSlide(currentSlideId  + 1);
  }
  
  $('.section-carousel button[data-slide="0"]').each(function(e) {
    $(this).addClass('active');
  });

  $('.section-carousel .carousel-nav').click(function(e) {
    e.stopPropagation();
    var id = $(this).data('slide');
    if (id != currentSlideId) {
      if (carouselTimer != null) {
        carouselTimer.pause();
      }
      carouselPaused = true;
      gotoSlide(id);
    }
  });

  $('.section-carousel .intro-video').on('timeupdate', function (e) {
    var duration = e.target.duration;
    var currentTime = e.target.currentTime;
    var remaining = (duration - currentTime)*1000;
    /*if (!introVideoPlayed && remaining <= transitionSpeed + 100) {
      introVideoPlayed = true;addclass
      gotoSlide(1);
    }*/
  });

  /*$('.section-carousel .intro-video').on('loadedmetadata', function (e) {
    var videoDuration = $(this)[0].duration * 1000;
    animateProgressBar(videoDuration);
  });*/

  function animateExploreMore() {
    $('.carousel-next .arrow .icon')
    .animate({ 'top': '20px' }, 400, 'easeOutBounce')
    .delay(100)
    .animate({ 'top': '0px' }, 300, 'easeOutExpo');
  }

  $('.section-carousel').mousemove(function(e) {
    //if (currentSlideId > 0) {
      if (e.pageX < window.innerWidth*navMargin) {
        $(this).removeClass('right-cursor');
        $(this).addClass('left-cursor');
      } else if (e.pageX > window.innerWidth*(1-navMargin)) {
        $(this).removeClass('left-cursor');
        $(this).addClass('right-cursor');
      } else {
        $(this).removeClass('right-cursor');
        $(this).removeClass('left-cursor');
      }
    //}
  });

  $('.section-carousel').click(function(e) {
    //if (currentSlideId > 0) {
      if (e.pageX < window.innerWidth*navMargin) {
        if (carouselTimer != null) {
          carouselTimer.pause();
        }
        carouselPaused = true;
        gotoPrevSlide();
      } else if (e.pageX > window.innerWidth*(1-navMargin)) {
        if (carouselTimer != null) {
          carouselTimer.pause();
        }
        carouselPaused = true;
        gotoNextSlide();
      }
    //}
  });

  if ($('.section-carousel').length) {
    setInterval(animateExploreMore, 3000);
  }

  $('.carousel-next').click(function(event){
    event.preventDefault();
    var anchor = $(this).find('a').attr('href');

    $('html, body').animate({
      scrollTop: $(anchor).offset().top
    }, 500);
  });

  $('.section-carousel').swipe( {
    preventDefaultEvents: false,
    swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
      if (carouselTimer != null) {
        carouselTimer.pause();
      }
      carouselPaused = true;
      if (direction == 'left') {
        gotoNextSlide();
      } else {
        gotoPrevSlide();
      }
    }
  });

  $('.section-carousel .intro-slide-title').each(function() {
    $('.intro-slide').removeClass('slide-hidden');
    var introDuration = 5000;
    //animateProgressBar(introDuration);
    startCarouselTimer(introDuration);
    /*var copy = $(this).data('intro-copy').trim();
    $(this).typed({
			strings: [copy],
			typeSpeed: 50
    });*/
    randomizeFirstSlide();
	});

  $('.section-makeit .heading-1 .typed').each(function() {
    $(this).typed({
			strings: ['matter.', 'tangible.', 'better.', 'happen.'],
      loop: true,
      backDelay: 1000,
			typeSpeed: 100
		});
	});
}

/*=[ HOMEPAGE LATEST ]========================================================*/

function HomeLatest() {
  $('.section-latest').each(function() {
    $(window).on('scroll DOMContentLoaded', function() {
      if (isBreakpointXLargeUp()) {
        var column1 = $('.section-latest .column-1');
        var column2 = $('.section-latest .column-2');
        var column3 = $('.section-latest .column-3');
  
        var maxHeight = Math.max(column1.outerHeight(), column2.outerHeight());
        maxHeight = Math.max(maxHeight, column3.outerHeight());
  
        var diff1 = maxHeight - column1.outerHeight();
        var diff2 = maxHeight - column2.outerHeight();
        var diff3 = maxHeight - column3.outerHeight();
  
        var start = $('.section-latest .column-container').offset().top;
        var end = start + maxHeight - window.innerHeight;
  
        diff1 = scale(window.scrollY, start, end, 0, diff1);
        diff2 = scale(window.scrollY, start, end, 0, diff2);
        diff3 = scale(window.scrollY, start, end, 0, diff3);
  
        column1.css('transform', 'translateY('+diff1+'px)');
        column2.css('transform', 'translateY('+diff2+'px)');
        column3.css('transform', 'translateY('+diff3+'px)');
      }
    });
  });
}
