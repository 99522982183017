 /*=[ TEAM CAROUSEL ]===============================================================*/

function TeamCarousel() {
  var bioActive = false;
  var teamTimer = null;
  var teamPage = 0;
  var teamCarouselOnHold = false;

  function getNumProfiles() {
    return isBreakpointSmallDown() ? 10 : 16;
  }

  function showBio(element) {
    if (isBreakpointSmallDown()) {
      var index = element.data('index');
      var row = Math.floor(index / 2) + 1;
      var rowInPage = row - (teamPage-1)*5;
      var height = 100/(teamPage*5);
      var maxHeight = 100 - (2*height);
      var topValue = rowInPage < 4 ? row * height : (row-3) * height;
      topValue = Math.min(topValue, maxHeight);
      leftValue = 0;
    } else {
      var index = element.index();
      var row = Math.floor(index / 4);
      var col = index % 4;
      var height = 25, width = 25;
      topValue = Math.min(row * height, height*2);
      leftValue = col < 2 ? (col+1) * width : (col-2) * width;
    }
    $('.section-team .overlay-wrapper').css('top', topValue+'%');
    $('.section-team .overlay-wrapper').css('left', leftValue+'%');

    $('.section-team .team').addClass('inactive');
    $('.section-team .team').css('animation-name', 'none');
    element.find('.team').removeClass('inactive');
    element.find('.team').addClass('selected');

    var content = element.find('.info').html();
    $('.section-team .overlay').html(content);
    $('.section-team .overlay-wrapper').fadeIn(300);

    bioActive = true;
  }

  function hideBio() {
    $('.section-team .team').removeClass('selected');
    $('.section-team .team').removeClass('inactive');
    $('.section-team .overlay-wrapper').fadeOut(300);

    bioActive = false;
  }

  $('.section-team .team-element').click(function(event) {
    event.stopPropagation();
    if (teamTimer != null) {
      teamTimer.pause();
    }
    if (bioActive) {
      hideBio();
    } else {
      showBio($(this));
    }
  });

  $(window).click(function(event) {
    if (bioActive) {
      event.stopPropagation();
      hideBio();
    }
  });

  function clearTeamPage() {
    $('.section-team .team').each(function (index) {
      $(this).find('.image').css('background-image', 'none');
      $(this).find('.name').text('');
      $(this).find('.position').text('');
      $(this).find('.office').text('');
      $(this).find('.bio').text('');
      $(this).find('.more a').attr('href', '');
      $(this).css('animation-name', 'none');
      void $(this)[0].offsetWidth; // restart the animation
    });
  }

  function fillTeamProfile(element, profile) {
    $(element).find('.image').css('background-image',
    'url("' + profile.thumbail_src + '")');
    $(element).find('.name').text(profile.name);
    $(element).find('.position').text(profile.position);
    $(element).find('.office').text(profile.office);
    $(element).find('.bio').text(profile.summary);
    $(element).find('.more a').attr('href', profile.path);
    $(element).css('animation-name', 'fadeTeamTile');
  }

  function fillTeamPage(profiles) {
    $('.section-team .team').each(function (index) {
      if (index < profiles.length) {
        var profile = profiles[index];
        fillTeamProfile($(this), profile);
      }
    });
  }

  function selectTeamBullet(explicit) {
    $('.section-team .bullet').each(function() {
      var fillDiv = $(this).find('.fill');
      var page = $(this).data('page-id');
      if (page < teamPage) {
        $(this).addClass('active');
        fillDiv.css('animation-name', 'none');
      } else if(page == teamPage)  {
        if (explicit) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
          fillDiv.css('animation-duration', teamRefreshDelay+'ms');
          fillDiv.css('animation-name', 'bulletFill');
        }
      } else {
        $(this).removeClass('active');
        fillDiv.css('animation-name', 'none');
      }
    });
  }

  function loadTeamPage(callback) {
    var numProfiles = getNumProfiles();
    var start = (teamPage - 1) * numProfiles;
    var end = (teamPage) * numProfiles;
    var requestIds = JSON.stringify(teamIds.slice(start, end));

    $.ajax({
      type: 'GET',
      url: ajaxUrl,
      cache: false,
      data: {
        'action': 'refresh_team',
        'ids': requestIds
      },
      success: function (response) {
        var parsed = $.parseJSON(response);
        var profiles = parsed['team_profiles'];
        callback(profiles);
      }
    });
  }

  function nextTeamPage() {
    var numPages = Math.ceil(teamIds.length / getNumProfiles());
    teamPage++;
    if (teamPage > numPages) {
      teamPage = 1;
    }
    clearTeamPage();
    loadTeamPage(function(profiles) {
      fillTeamPage(profiles);
      if (!isBreakpointSmallDown()) {
        scheduleRefreshTeam();
      }
    });
  }

  function scheduleRefreshTeam() {
    $('.section-team .team-wrapper').each(function() {
      var rect = $(this)[0].getBoundingClientRect();
      if (rect.top < window.innerHeight) {
        selectTeamBullet(false);
        teamTimer = new Timer(nextTeamPage, teamRefreshDelay);
        teamCarouselOnHold = false;
      } else {
        teamCarouselOnHold = true;
      }
    });
  }

  $(window).scroll(function() {
    if (teamCarouselOnHold) {
      scheduleRefreshTeam();
    }
  });

  $('.section-team .bullet').click(function() {
    teamTimer.pause();
    teamPage = $(this).data('page-id');
    clearTeamPage();
    loadTeamPage(function(profiles) {
      selectTeamBullet(true);
      fillTeamPage(profiles);
    });
  });


  $('.section-team .load-more').click(function() {
    var numPages = Math.ceil(teamIds.length / getNumProfiles());
    teamPage++;
    if (teamPage >= numPages) {
      $(this).hide();
    }
    loadTeamPage(function(profiles) {
      var prototype = $('.section-team .team-element').first();
      var offset = (teamPage-1) * 10;
      for (var i = 0 ; i < profiles.length; i++) {
        var profile = profiles[i];
        var clone = prototype.clone(true);
        clone.data('index', offset+i);
        fillTeamProfile(clone.find('.team'), profile);
        clone.appendTo('.section-team .team-container');
      }
    });
  });

  if ($('.section-team').length) {
    shuffle(teamIds);
    nextTeamPage();
  }
}
