/*=[ UTILITY ]==============================================================*/

function scale(val, minInput, maxInput, minOutput, maxOutput) {
  if (minInput == maxInput || minOutput == maxOutput) {
    return minOutput;
  } else if (val < minInput) {
    return minOutput;
  } else if (val > maxInput) {
    return maxOutput;
  } else {
    return minOutput + (maxOutput - minOutput) * ((val - minInput) / (maxInput - minInput));
  }
}

// small: 0, // phone (iPhone 6 is 414×736)
// medium: 640px, // tablet (iPad and iPad mini are 768x1024)
// large: 1024px, // laptop (Macbook Pro is 1440x900)
// xlarge: 1441px, // desktop (1920x1080)
// xxlarge: 1921px // Apple display (2560x1440)

function isBreakpointXLargeUp(){
  return window.matchMedia( '(min-width: 1441px)' ).matches;
}
function isBreakpointLargeUp(){
  return window.matchMedia( '(min-width: 1024px)' ).matches;
}
function isBreakpointMediumUp(){
  return window.matchMedia( '(min-width: 640px)' ).matches;
}

function isBreakpointLargeDown(){
  return window.matchMedia( '(max-width: 1440px)' ).matches;
}
function isBreakpointMediumDown(){
  return window.matchMedia( '(max-width: 1023px)' ).matches;
}
function isBreakpointSmallDown(){
  return window.matchMedia( '(max-width: 639px)' ).matches;
}

function shuffle(a) {
  var j, x, i;
  for (i = a.length; i; i--) {
    j = Math.floor(Math.random() * i);
    x = a[i - 1];
    a[i - 1] = a[j];
    a[j] = x;
  }
}

function Timer(callback, delay) {
  var timerId, start, remaining = delay;

  this.pause = function() {
    window.clearTimeout(timerId);
    remaining -= new Date() - start;
  };

  this.resume = function() {
    start = new Date();
    window.clearTimeout(timerId);
    timerId = window.setTimeout(callback, remaining);
  };

  this.resume();
}

function stopAllVideos() {
  $('video').each(function() {
    var video = $(this);
    //console.log('STOP VIDEO ' + video[0].src);
    //console.log('STOP VIDEO ' + video.children('source').prop('src'));
    video[0].pause();
    video[0].src = ''; // unload the video
    video.children('source').prop('src', '');
    //video.remove().length = 0; // dispose HTML element
  });
}

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault) {
      e.preventDefault();
    }
  e.returnValue = false;
}

function disableScrolling() {
  window.onwheel = preventDefault;
  window.ontouchmove  = preventDefault;
}

function enableScrolling() {
  window.onwheel = null;
  window.ontouchmove  = null;
}
