/*=[ NAV BAR ]==============================================================*/

function NavBar() {
  this.toggleNavBar = function() {
    $('.lines-button').toggleClass('close');
    $('.search-button').toggleClass('nav-active');
    $('.logo').toggleClass('nav-active');
    $('.nav-menu-pages').toggleClass('nav-active');
    $('.page-display').toggleClass('nav-active');
    $('#overlay').fadeToggle();
  }

  $('.lines-button, #overlay').click(this.toggleNavBar);

  $(window).scroll(function() {
    $('.lines-button').removeClass('close');
    $('.search-button').removeClass('nav-active');
    $('.logo').removeClass('nav-active');
    $('.nav-menu-pages').removeClass('nav-active');
    $('.page-display').removeClass('nav-active');
    $('#overlay').fadeOut();
  });

  function hideSearch() {
    $('.nav-wrapper').removeClass('search-active');
    $('.search-wrapper').removeClass('search-active');
    $('#search-overlay').removeClass('search-active');
    enableScrolling();
  }

  function showSearch() {
    $('.nav-wrapper').addClass('search-active');
    $('.search-wrapper').addClass('search-active');
    $('#search-overlay').addClass('search-active');
    $('.search-bar .search-input').focus();
    disableScrolling();
    $(window).keydown(function(event) {
      if (event.which == 27) {
        $(window).off('keydown');
        hideSearch();
      }
    });
  }

  $('.search-button').click(showSearch);
  $('.search-bar .close-button, #search-overlay').click(hideSearch);

  $('.search-bar .search-input').keypress(function(event) {
    if (event.which == 13) {
      event.preventDefault();
      $('.search-bar form').submit();
      return false;
    }
  });

  var headerElement = document.querySelector('header');
  var headroom = new Headroom(headerElement, {
    tolerance : {
      up : 6,
      down : 0
    },
    onPin: function () {
      $(headerElement).removeClass('headroom-top');
      $(headerElement).addClass('headroom-not-top');
    },
    onUnpin: function () {
      //console.log('unpinned');
    },
    onTop: function () {
      $(headerElement).removeClass('headroom-not-top');
      $(headerElement).addClass('headroom-top');
    },
    onNotTop: function () {
      if (!$(headerElement).hasClass('headroom-top')) {
        $(headerElement).removeClass('headroom-top');
        $(headerElement).addClass('headroom-not-top');
      }
    }
  });
  headroom.init();

  this.initNavBar = function() {
    if (window.scrollY > 0) {
      $(headerElement).removeClass('headroom-not-top');
      $(headerElement).addClass('headroom-not-top');
      $(headerElement).removeClass('headroom-top');
    }
  }

  this.initNavBar();

}
