/*=[ PROJECT CAROUSEL ]=====================================================*/

function ProjectCarousel() {
  var slickNavMargin = 0.35;

  $('.normal-width .slick-carousel').on('click', '.slick-slide', function(){
    var index = $(this).data('slick-index');
    var currentIndex = $('.slick-current').data('slick-index');

    if (index > currentIndex) {
      $('.slick-carousel').slick('slickNext');
    } else if (index < currentIndex) {
      $('.slick-carousel').slick('slickPrev');
    }
  });

  $('.normal-width .slick-carousel').on('mouseenter', '.slick-slide', function(){
    var index = $(this).data('slick-index');
    var currentIndex = $('.slick-current').data('slick-index');

    $(this).removeClass('right-cursor');
    $(this).removeClass('left-cursor');
    if (index > currentIndex) {
      $(this).addClass('right-cursor');
    } else if (index < currentIndex) {
      $(this).addClass('left-cursor');
    }
  });

  $('.full-width .slick-carousel').on('mousemove', '.slick-slide' ,function(e) {
    var offset = $(this).offset();
    var relX = e.pageX - offset.left;
    var width = $(this).width();

    if (relX < width*slickNavMargin) {
      $(this).removeClass('right-cursor');
      $(this).addClass('left-cursor');
    } else if (relX > width*(1-slickNavMargin)) {
      $(this).removeClass('left-cursor');
      $(this).addClass('right-cursor');
    } else {
      $(this).removeClass('right-cursor');
      $(this).removeClass('left-cursor');
    }
  });

  $('.full-width .slick-carousel').on('click', '.slick-slide', function(e) {
    var offset = $(this).offset();
    var relX = e.pageX - offset.left;
    var width = $(this).width();

    if (relX < width*slickNavMargin) {
      $('.slick-carousel').slick('slickPrev');
    } else if (relX > width*(1-slickNavMargin)) {
      $('.slick-carousel').slick('slickNext');
    }
  });

  $('.full-width .slick-carousel').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    centerMode: false,
    centerPadding: '100px',
    dots: true,
    arrows: false,
    easing: 'linear',
    cssEase: 'ease'
  });

  $('.normal-width .slick-carousel').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    centerPadding: '100px',
    dots: true,
    arrows: false,
    easing: 'linear',
    cssEase: 'ease'
  });

}

 /*=[ VIDEO MOSAIC ]=========================================================*/

function VideoMosaic() {
  $('.video-mosaic .thumb-wrapper').mouseenter(function() {
    var video = $(this).find('video')[0];
    if (video.paused) {
      video.play();
      $(this).find('.play-button').fadeOut();
    }
  });

  $('.video-mosaic .thumb-wrapper').mouseleave(function() {
    var video = $(this).find('video')[0];
    if (!video.paused) {
      video.pause();
      $(this).find('.play-button').fadeIn();
    }
  });
}
