/*=[ IDEAS ]================================================================*/

function IdeasLoader() {
  var ideasPostsPerPage = 12;
  var ideasPostOffset = 10;

  function fillMoreTiles(articles) {
    var prototype = $('.section-thinking .article-tile').first();
    for (var i = 0 ; i < articles.length; i++) {
      var article = articles[i];
      var clone = prototype.clone();

      clone.find('.author-span').removeClass('hidden');
      clone.find('.author-link').removeClass('hidden');
      if (article['author_hidden'] == '1') {
        clone.find('.author-link').addClass('hidden');
      } else {
        clone.find('.author-span').addClass('hidden');
      }

      clone.find('.tile-type').html(article['topic']);
      clone.find('.tile-title a').html(article['title']);
      clone.find('.tile-title a').attr('href', article['path']);
      clone.find('.clickable').data('href', article['path']);
      clone.find('.article-preview p').html(article['summary']);
      clone.find('.article-date').html(article['date']);
      clone.find('.image').css('background-image',
        'url("' + article['thumbnail_src'] + '")');
      clone.find('.author-span').html(article['author_name']);
      clone.find('.author-link').html(article['author_name']);
      clone.find('.author-link').attr('href', article['author_path']);
      clone.find('.author-thumb').css('background-image',
        'url("' + article['author_thumb'] + '")');

      if (article.hasOwnProperty('coauthor_name')) {
        clone.find('.coauthor-name').removeClass('hidden');
        clone.find('.coauthor-span').removeClass('hidden');
        clone.find('.coauthor-thumb').removeClass('hidden');
        clone.find('.coauthor-link').removeClass('hidden');
        if (article['coauthor_hidden'] == '1') {
          clone.find('.coauthor-link').addClass('hidden');
        } else {
          clone.find('.coauthor-span').addClass('hidden');
        }

        clone.find('.coauthor-span').html(article['coauthor_name']);
        clone.find('.coauthor-link').html(article['coauthor_name']);
        clone.find('.coauthor-link').attr('href', article['coauthor_path']);
        clone.find('.coauthor-thumb').css('background-image',
          'url("' + article['coauthor_thumb'] + '")');
      } else {
        clone.find('.coauthor-name').addClass('hidden');
        clone.find('.coauthor-span').addClass('hidden');
        clone.find('.coauthor-thumb').addClass('hidden');
        clone.find('.coauthor-link').addClass('hidden');
      }

      clone.appendTo('.section-thinking .tile-container');
    }
  }

  $('.section-thinking .more').click(function (){
    var offset = ideasPostOffset;
    var count = ideasPostsPerPage;

    $.ajax({
      type: 'GET',
      url: ajaxUrl,
      cache: true,
      data: {
        'action': 'load_more_articles',
        'offset': offset,
        'count': count
      },
      success: function (response) {
        var parsed = $.parseJSON(response);
        var articles = parsed['articles'];
        ideasPostOffset += articles.length;
        var totalCount = $('.section-thinking .tile-container').data('article-count');
        if (ideasPostOffset >= totalCount) {
          $('.section-thinking .more').hide();
        }
        fillMoreTiles(articles);
      }
    });
  });
}

/*=[ NEWS ]=================================================================*/

function NewsLoader() {
  var newsPostsPerPage = 20;
  var newsPostOffset = newsPostsPerPage;

  function fillMoreNews(articles) {
    var prototype = $('.section-news .news-wrapper').first();
    for (var i = 0 ; i < articles.length; i++) {
      var article = articles[i];
      var clone = prototype.clone();
      clone.find('.topic').html(article['topic']);
      clone.find('.heading a').html(article['title']);
      clone.find('.heading a').attr('href', article['path']);;
      clone.find('.subheading').html(article['introduction']);
      clone.data('href', article['path']);
      clone.find('.date').html(article['date']);
      clone.find('.image').css('background-image',
        'url("' + article['thumbnail_src'] + '")');
      clone.appendTo('.section-news .tile-container');
    }
  }

  $('.section-news .more').click(function (){
    var offset = newsPostOffset;
    var count = newsPostsPerPage;

    $.ajax({
      type: 'GET',
      url: ajaxUrl,
      cache: true,
      data: {
        'action': 'load_more_news',
        'offset': offset,
        'count': count
      },
      success: function (response) {
        var parsed = $.parseJSON(response);
        var articles = parsed['articles'];
        newsPostOffset += articles.length;
        var totalCount = $('.section-news .tile-container').data('article-count');
        if (newsPostOffset >= totalCount) {
          $('.section-news .more').hide();
        }
        fillMoreNews(articles);
      }
    });
  });
}
