/*=[ PAGE BEHAVIORS ]=======================================================*/

function PageBehaviors() {
  var fadeMargin = 100;

  $( '.fadeable' ).each(function() {
    var rect = $(this)[0].getBoundingClientRect();
    if (rect.top < window.innerHeight) {
      $(this).css('opacity', 1.0);
      $(this).css('transition', 'opacity 0s ease');
    }
  });

  $(window).scroll(function() {
    $( '.fadeable' ).each(function() {
      var rect = $(this)[0].getBoundingClientRect();
      if (rect.top < window.innerHeight - fadeMargin) {
        if (!$(this).hasClass('revealed')) {
          $(this).addClass('revealed');
        }
      }
    });
  });

  $(window).on('scroll DOMContentLoaded', function() {
    var startScroll = window.innerHeight/10;
    var midScroll = window.innerHeight/3;
    var endScroll = window.innerHeight;

    var opacity = scale(window.scrollY, midScroll, endScroll-200, 1, 0);
    var top = scale(window.scrollY, midScroll, endScroll-200, 0, -200);

    $('.section-hero').css('opacity', opacity);
    $('.section-hero').css('top', top+'px');

    var titleOpacity1 = scale(window.scrollY, startScroll, midScroll, 1, 0);
    var titleOpacity2 = scale(window.scrollY, startScroll+50, midScroll, 1, 0);
    var titleOpacity3 = scale(window.scrollY, startScroll+100, midScroll, 1, 0);

    $('.animated-title-1').css('opacity', titleOpacity1);
    $('.animated-title-2').css('opacity', titleOpacity2);
    $('.animated-title-3').css('opacity', titleOpacity3);

    var titleTranslation1 = scale(window.scrollY, startScroll, midScroll, 0, -100);
    var titleTranslation2 = scale(window.scrollY, startScroll+50, midScroll, 0, -100);
    var titleTranslation3 = scale(window.scrollY, startScroll+100, midScroll, 0, -100);

    $('.animated-title-1').css('transform', 'translateY('+titleTranslation1+'px)');
    $('.animated-title-2').css('transform', 'translateY('+titleTranslation2+'px)');
    $('.animated-title-3').css('transform', 'translateY('+titleTranslation3+'px)');
  });

  $(window).on('scroll DOMContentLoaded', function() {
    //if ( window.scrollY > 0) {
    if ( window.scrollY > $( document ).height()-2000) {
      $('.footer').addClass('visible');
    } else {
      $('.footer').removeClass('visible');
    }
  });

}

/*=[ PAGE TRANSITIONS ]=========================================================*/

function PageTransitions(homeCarouselInstance, navBarInstance) {
  var homeCarousel = homeCarouselInstance;
  var navBar = navBarInstance;

  function animateIntoLevel2(inOut, depthDir) {
    $('.hero-image')
      .css('animation-name', 'zoomImage'+depthDir+inOut)
      .css('animation-duration', '300ms');

    $('.titles')
      .css('animation-name', 'zoomText'+depthDir+inOut)
      .css('animation-duration', '500ms')
      .css('animation-delay', '100ms');

    $('.animated-text')
      .css('animation-name', 'zoomText'+depthDir+inOut)
      .css('animation-duration', '500ms')
      .css('animation-delay', '300ms');
  }

  function selectionAnimation(inOut, horizontalDir, depthDir) {
    var animation;
    if (depthDir == 'In' || depthDir == 'Out') {

      if ((inOut == 'Start' && depthDir == 'In')
      || (depthDir == 'Out')) {
        var originX = 'center';
        if (currentDepth != 0 && targetDepth != 0) {
          if (horizontalDir == 'Left') {
            originX = 'left';
          } else if (horizontalDir == 'Right') {
            originX = 'right';
          }
        }
        var originY = window.scrollY + window.innerHeight/2;

        if (depthDir == 'Out' && depthId == 2) {
          $('.section-hero')
            .css('animation-name', 'zoom'+depthDir+inOut)
            .css('transform-origin', originX + ' ' + originY + 'px')
            .css('animation-duration', '300ms');

          /*var originYFold = originY - $('.section-hero').outerHeight(true);
          $('.below-fold')
            .css('animation-name', 'zoom'+depthDir+inOut)
            .css('transform-origin', originX + ' ' + originYFold + 'px')
            .css('animation-duration', '300ms');*/
        } else {
          $('#content')
            .css('animation-name', 'zoom'+depthDir+inOut)
            .css('transform-origin', originX + ' ' + originY + 'px')
            .css('animation-duration', '300ms');
        }

      } else if (inOut == 'End' && depthDir == 'In') {
        animateIntoLevel2(inOut, depthDir);
      }

    } else if (horizontalDir == 'Right' || horizontalDir == 'Left') {
      if (inOut == 'Start') {
        $('#transition-mask')
          .css('animation-name', 'slide'+horizontalDir+inOut)
          .css('animation-duration', '300ms');
      } else {
        $('#header')
          .css('animation-name', 'slide'+horizontalDir+inOut)
          .css('animation-delay', '300ms')
          .css('animation-duration', '300ms');
        $('#content')
          .css('animation-name', 'slide'+horizontalDir+inOut)
          .css('animation-delay', '300ms')
          .css('animation-duration', '300ms');
      }

    } else if (currentDepth == 2 && targetDepth == 2) {
      $('#content')
        .css('animation-name', 'fade'+inOut)
        .css('animation-duration', '300ms');
    }
  }

  var options = {
    prefetch: true,
    cacheLength: 20,
    anchors: 'a, .clickable, .clickable-twostep',
    forms: '',
    blacklist: '#main, .toggler',
    onBefore: function($anchor, $container) {
      if(isBreakpointMediumDown() && $anchor.hasClass("clickable-twostep")
        && !$anchor.hasClass("clicked")) {
        $anchor.addClass("clicked");
        return false;
      }
      $anchor.removeClass("clicked");

      var currentUrl = window.location.href;
      scrollPositions[currentUrl] = window.scrollY;

      homeCarousel.stopCarousel();
      //enableScrolling(scrollDisabledCallback);

      if ($('#overlay').is(':visible')) {
        navBar.toggleNavBar();
      }

      if ($anchor.data('nav-id')) {
        if (depthId == 0) {
          $('.underline').css('animation-name',
            'navHighlightFromHome');
        } else {
          $('.underline').css('animation-name',
            'navHighlight' + $anchor.data('nav-id'));
        }
      }

      var currentHorizontal = horizontalId;
      var targetHorizontal = $anchor.data('horizontal-id');
      directionHorizontal = 'unchanged';
      if (currentHorizontal > targetHorizontal) {
        directionHorizontal = 'Right';
      } else if (currentHorizontal < targetHorizontal) {
        directionHorizontal = 'Left';
      }
      directionDepth = 'unchanged';
      currentDepth = depthId;
      targetDepth = $anchor.data('depth-id');
      if (currentDepth > targetDepth) {
        directionDepth = 'Out';
      } else if (currentDepth < targetDepth) {
        directionDepth = 'In';
      }
      anchorClicked = true;
    },
    onStart: {
      duration: 300,
      render: function ($container) {
        $('.footer').removeClass('visible');
        if (!anchorClicked) {
          if (directionHorizontal == 'Left') {
            directionHorizontal = 'Right';
          } else if (directionHorizontal == 'Right') {
            directionHorizontal = 'Left';
          }
          if (directionDepth == 'In') {
            directionDepth = 'Out';
          } else if (directionDepth == 'Out') {
            directionDepth = 'In';
          }
        }
        selectionAnimation('Start', directionHorizontal, directionDepth);
        smoothState.restartCSSAnimations();
        stopAllVideos();
      }
    },
    onReady: {
      duration: 0,
      render: function ($container, $newContent) {
        $('#main').html($newContent);
        selectionAnimation('End', directionHorizontal, directionDepth);

        
        if (!scrollToAnchor()) {
          var currentUrl = window.location.href;
          window.scrollTo(0, scrollPositions[currentUrl]);
        }
        
      }
    },
    onAfter: function($container, $newContent) {
      $(window).off('scroll');
      init();
      anchorClicked = false;

      navBar.initNavBar();
    }
  };

  smoothState = $('#main').smoothState(options).data('smoothState');

  $(window).scroll(function() {
    $('#header')
      .css('animation-name', '')
      .css('animation-delay', '')
      .css('animation-duration', '');
  });

  $('.clickable').click(function(e) {
    if (e.metaKey || e.ctrlKey) {
      var href = $(this).data('href');
      window.open(href,'_blank');
    }
  });

  $('.clickable-ext').click(function(e) {
      var href = $(this).data('href');
      window.open(href,'_blank');
  });

  if (depthId == 2) {
    animateIntoLevel2('End', 'In');
  }

}
